import img01 from "../assets/images/OLYMPO310/ESTANDAR/salon-pasajeros---acabado-techo.jpg"
// import img02 from "../assets/images/olympo36/img02.jpg"
import img02 from "../assets/images/OLYMPO310/ESTANDAR/salon-pasajeros---acabado-lateral.jpg"
// import img03 from "../assets/images/olympo36/img03.jpg"
import img03 from "../assets/images/OLYMPO310/ESTANDAR/salon-pasajeros---acabado-piso.jpg"
// import img04 from "../assets/images/olympo36/img04.jpg"
import img04 from "../assets/images/OLYMPO310/ESTANDAR/salon-pasajeros---iluminacion.jpg"
// import img05 from "../assets/images/olympo36/img05.jpg"
// import img06 from "../assets/images/olympo36/img06.jpg"
import img06 from "../assets/images/OLYMPO310/ESTANDAR/cabina-conductor---cupula.jpg"
// import img07 from "../assets/images/olympo36/img07.jpg"
import img07 from "../assets/images/OLYMPO310/ESTANDAR/cabina-conductor---mampara-divisoria.jpg"
// import img08 from "../assets/images/olympo36/img08.jpg"
import img08 from "../assets/images/OLYMPO310/ESTANDAR/cabina-conductor---tablero-de-control.jpg"
// import img09 from "../assets/images/olympo36/img09.jpg"
import img09 from "../assets/images/OLYMPO310/ESTANDAR/cabina-conductor---grada-de-ingreso.jpg"
// import img10 from "../assets/images/olympo36/img10.jpg"
import img10 from "../assets/images/OLYMPO310/ESTANDAR/exterior-2.jpg"
// import img11 from "../assets/images/olympo36/img11.jpg"
import img11 from "../assets/images/OLYMPO310/ESTANDAR/exterior-3.jpg"
// import img12 from "../assets/images/olympo36/img12.jpg"
// import img13 from "../assets/images/olympo36/img13.jpg"
import img13 from "../assets/images/OLYMPO310/ESTANDAR/calefaccion.jpg"
// import img14 from "../assets/images/olympo36/img14.jpg"
import img14 from "../assets/images/OLYMPO310/ESTANDAR/salon-pasajeros---asientos.jpg"
// import img15 from "../assets/images/olympo36/img15.jpg"
import img15 from "../assets/images/OLYMPO310/OPCIONALES/aire-acondicionado.jpg"
// import img17 from "../assets/images/olympo36/img17.jpg"
import img17 from "../assets/images/OLYMPO310/ESTANDAR/conduccion---espejos-retrovisores.jpg"
// import img18 from "../assets/images/olympo36/img18.jpg"
import img18 from "../assets/images/OLYMPO310/ESTANDAR/conduccion---accionamiento-de-puerta-de-servicio.jpg"
import img19 from "../assets/images/OLYMPO310/ESTANDAR/conduccion---panel-de-control.jpg"
import img20 from "../assets/images/OLYMPO310/ESTANDAR/exterior-1.jpg"
export const OlympoBlock1 = [
  {
    title: `ACABADO TECHO`,
    text: `Impermeable, de facil limpieza, antinflamable y aislante térmico.<br/> Decorado con PVC DECORATIVO.`,
    img: img01,
  },
  {
    title: `ACABADO LATERALES`,
    text: `Impermeable, de facil limpieza, antinflamable y aislante térmico.<br/> Perfilado de marcos de ventanas con PVC DECORATIVO de brillo natural.`,
    img: img02,
  },
  {
    title: `ACABADO DE PISO`,
    text: `Con PVC FLEXIBLE DE ALTO TRÁNSITO de alta resistencia al desgaste.<br/> Opciones de colores para el decorado del piso. Es antinflamable y retardante.`,
    img: img03,
  },
  {
    title: `ILUMINACIÓN`,
    text: `Iluminación con tecnología LED en ambos lados del salón.`,
    img: img04,
  },
  {
    title: `ASIENTOS`,
    text: `Ergonómicos inyectados en espuma de poliuretano flexible.<br/> Personalización de asientos de acuerdo a necesidades del cliente.`,
    img: img14,
  },
  {
    title: `CALEFACCIÓN`,
    text: `En base a paneles de serpentín.`,
    img: img13,
  },
  // {
  //   title: `PAQUETERAS`,
  //   text: ` LIGERAS y elaboradas en poliuretano rigido de alta densidad. Fijadas en el techo estratégicamente para EVITAR VIBRACIONES. Luces de lectura individuales con iluminación independiente. `,
  //   img: img05,
  // },
]
export const OlympoBlock2 = [
  {
    title: `TABLERO DE CONTROL`,
    text: `Diseño AERODINÁMICO elaborado en fibra de vidrio con colores elegantes.<br/> Su diseño FACILITA LA OPERACIÓN del ómnibus.`,
    img: img08,
  },
  {
    title: `GRADA DE INGRESO`,
    text: `Fabricado en fibra de vidrio reforzada con estructura metálica.<br/> Iluminación propia con perfil aluminio con su respectiva luz de paso. `,
    img: img09,
  },
  {
    title: `CÚPULA`,
    text: `Techo de cabina hermetizado. Elaborado en fibra de vidrio con lineas estelizadas decorativas y definición de contornos para la colocación de accesorios de cabina.`,
    img: img06,
  },
  {
    title: `MAMPARA DIVISORIA`,
    text: `Diseño panorámico con vidrios templados de acuerdo a norma y fibra de vidrio.<br/> Puerta amplia de acceso al salón de pasajeros. `,
    img: img07,
  },
]
export const OlympoBlock3 = [
  {
    
    title:"ISO FRONTAL",
    text:'AERODINÁMICO Y ELEGANTE',
    img:img20
  },
  {
    title: ` FRONTAL`,
    text: `  Mascara, Parachoque y Trampilla rebatible.`,
    img: img10,
  },
  {
    title: `BODEGAS`,
    text: ` AMPLIAS Y HERMÉTICAS`,
    img: img11,
  }
  // {
  //   title: `BODEGAS`,
  //   text: ` Livianas, forradas en aluminio. Puertas de bodegas cuentan con mecanismo de seguridad de doble traba que garantizan un sello hermético.  `,
  //   img: img12,
  // },
]
export const OlympoBlock4 = [
  
  {
    title: `AIRE ACONDICIONADO`,
    text: `Purifica y climatiza el aire del salón de pasajeros, garantizando comodidad y bienestar a los pasajeros.`,
    img: img15,
  }
]
export const OlympoBlock5=[
  {
    title:"ESPEJOS RETROVISORES",
    text:`Diseñados para obtener la mejor visibilidad de conducción. Mando eléctrico que regula el angulo de visión del conductor. <br/>Espejos calefactables para climas humedos.`,
    img:img17
  },
  {
    title:'ACCIONAMIENTO PUERTA SERVICIO',
    text:'Accionado por aire y mando de accionamiento externo.',
    img:img18
  },
  {
    title:'PANEL DE CONTROL',
    text:'Panel digital con tecnología de circuitos impresos con protección “Anti – solder” que garantizan la funcionalidad correcta del bus en los diferentes climas y sin riesgo de cortocircuito.',
    img:img19
  }
]


export const OlympoBlock6=[
  
  

]

